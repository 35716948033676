.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

h1 {
  font-size: 25px;
  font-weight: bold;
}

div{
  font-size: 25px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.input-style {
  border: 1.5px solid #174337;
  padding: 7px 20px;
  margin: 0px 0 0;
  border-radius: 10px;
  font-size: 14px;
}

.input-style:focus {
  border: 1.5px solid #480202;

}

.sign-in-button-11 {
  cursor: pointer;
  background-color: #156cf2;
  margin: 10px 0px 0;
  padding: 7px 10px;
  border: 1.5px solid #156cf2;
  min-width: 150px;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-align: center;
}

.sign-in-button-11:hover {
  transform: scale(1.1);
}


.sign-in-button-1 {
  cursor: pointer;
  background-color: #156cf2;
  margin: 10px 0px 0;
  padding: 7px 10px;
  border: 1.5px solid #156cf2;
  min-width: 150px;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-align: center;
}
.sign-in-button-1-media-on {
  cursor: pointer;
  background-color: #156cf2aa;
  margin: 10px 0px 0;
  padding: 7px 10px;
  border: 1.5px solid #156cf2aa;
  min-width: 150px;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-align: center;
}
.sign-in-button-4 {
  cursor: pointer;
  background-color: #fff;
  margin: 10px 0px 0;
  padding: 7px 10px;
  border: 1.5px solid #480202;
  min-width: 150px;
  /* width: 170px; */
  color: #000;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-align: center;
}

.sign-in-button-1:hover {
  border: 1.5px solid #004233;
  transform: scale(1.1);
}

.sign-in-button-2:hover {
  border: 1.5px solid #480202;
  transform: scale(1.1);
}

.sign-in-button-4:hover {
  border: 1.5px solid #480202;
  transform: scale(1.1);
}

.label-form {
  text-align: left;
  font-size: 12px;
  color: #666;
  margin: 10px 10px 0;
}

.showPassword {
  text-align: left;
  font-size: 12px;
  color: #666;
  margin: 10px 0px 0;

}

.forget-password {
  color: #004233;
  display: 'flex';
  justify-self: 'flex-end';
  text-align: right;
}

.forget-password:hover {
  text-decoration-line: underline;
}

.sign-in-button-2 {
  cursor: pointer;
  background-color: #004233;
  margin: 10px 0 0;
  /* width: 170px; */
  padding: 7px 0px;
  border: 1.5px solid #004233;
  color: #fff;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-align: center;
}

.sign-in-button-3 {
  cursor: pointer;
  background-color: #004233;
  border: 1.5px solid #004233;
  padding: 7px 0px;
  color: #fff;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-align: center;
}

.all-projects-listing-button-1 {
  cursor: pointer;
  border-right: 1.5px solid #480202;
  padding: 7px 0px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-align: center;
}

.all-projects-listing-button-1:hover {
  background-color: #00423322;
}

.all-projects-listing-button-2:hover {
  background-color: #004233aa;
}

.all-projects-listing-button-2 {
  cursor: pointer;
  border-right: 1.5px solid #480202;
  background-color: #004233;
  color: #fff;
  padding: 7px 0px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-align: center;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  box-shadow: 9999px 0 0 -5px #fff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: .25s;
}

.dot-pulse::before, .dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #fff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #fff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: .5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #fff;
  }

  30% {
    box-shadow: 9984px 0 0 2px #fff;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #fff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #fff;
  }

  30% {
    box-shadow: 9999px 0 0 2px #fff;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #fff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #fff;
  }

  30% {
    box-shadow: 10014px 0 0 2px #fff;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #fff;
  }
}

.center {
  text-align: center;
  align-self: center;
  justify-content: center;
}

.Link {
  text-decoration-line: none;
  color: #000;
}

.Link:hover {
  text-decoration-line: none;
  color: #000;
}

.modal {
  display: block;
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  padding-top: 60px;
}

@media screen and (min-width: 600px) {
  .signup-container {
    margin: 10vh 0 0 0;
  }

  .signin-container {
    margin: 5vh 0 0 0;
  }
}

@media screen and (max-width: 600px) {
  .signup-container {
    margin: 3vh 0 0 0;
  }

  .signin-container {
    margin: 15vh 0 0 0;
  }
}




.dot-pulse-2 {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #000;
  color: #000;
  box-shadow: 9999px 0 0 -5px #000;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: .25s;
}

.dot-pulse-2::before, .dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #000;
  color: #000;
}

.dot-pulse-2::before {
  box-shadow: 9984px 0 0 -5px #000;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse-2::after {
  box-shadow: 10014px 0 0 -5px #000;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: .5s;
}